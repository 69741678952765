/* eslint-disable no-nested-ternary */
import React from 'react'
import { Layout } from '../../components/layout'
import Meta from '../../components/layout/meta'
import { RelativeContainer, ComponentContainer, ColoredBackgroundWithProps } from '../../components/shared/landingPages/layoutComponents'
import Header from '../../components/shared/landingPages/header'
import TopHighlights from '../../components/shared/landingPages/topHighlights'
import HighlightedFunctionality from '../../components/shared/landingPages/highlightedFunctionality'
import Benefits from '../../components/shared/landingPages/benefits'
import FeatureLinks from '../../components/shared/landingPages/featureLinks'
import Cash from '../../images/koppel-je-rekening/cash.js'
import Graph from '../../images/koppel-je-rekening/graph.js'
import Shield from '../../images/koppel-je-rekening/shield.js'
import Credit from '../../images/icons/credit.js'
import BankConnectionLottie from '../../images/lottieAnimations/BankConnectionAnimation.js'
import secure from '../../images/koppel-je-rekening/secure.png'
import benefit from '../../images/koppel-je-rekening/benefits.png'

const topHighlightData = {
    title: `Je bankrekening koppelen aan Tellow`,
    points: [
        {
            Icon: Cash,
            title: `Grip op je geld`,
            body: `
            Zie precies hoeveel geld er binnenkomt en waar je het aan uitgeeft.
        `,
        },
        {
            Icon: Graph,
            title: `Overzicht`,
            body: `
            Alles wordt op één plek bewaard zodat je een duidelijk overzicht creëert in je boekhouding.
        `,
        },
        {
            Icon: Shield,
            title: `Belastingvoordeel`,
            body: `
            Nooit meer fiscale aftrekposten over het hoofd zien.
        `,
        },
        {
            Icon: Credit,
            title: `Betalingen`,
            body: `
            Zie direct welke klanten betaald hebben en wie je nog een herinnering moet sturen.
        `,
        },
    ],
}

const headerData = {
    title: `Bankrekening koppelen`,
    text: `Koppel Tellow eenvoudig aan jouw (zakelijke) bankrekening zodat al je transacties automatisch worden ingeladen. Binnen enkele seconden zijn ze verwerkt in je administratie.`,
    animation: <BankConnectionLottie />,
    textCTA: `Gebruik Tellow gratis`,
}

const highlightedFunctionalityData = {
    title: `Veilig`,
    subtitle: `Via een partner met een PSD2-vergunning zorgen we ervoor dat je zonder zorgen je bankrekening aan je Tellow-account kunt koppelen. Al je betaalgegevens blijven veilig en we vragen je om de 90 dagen opnieuw om toestemming.`,
    firstStepImage: secure,
    textCTA: `Probeer het zelf`,
}

const featureData = [
    {
        Icon: Credit,
        title: `Btw-aangifte doen`,
        text: `Door de koppeling met de belastingdienst doe jij nu je aangifte in enkele klikken. Super makkelijk en snel.`,
        link: `Lees meer over aangiftes doen met Tellow`,
        path: `btw-aangifte`,
    },
    {
        Icon: Credit,
        title: `Offertes`,
        text: `Stuur professionele offertes en zet deze direct om in facturen. Verhoog de acceptatieratio van je offertes door je eigen huisstijl en logo te gebruiken.`,
        link: `Lees meer over offertes maken`,
        path: `offertes`,
    },
]

const benefitsData = {
    points: [
        {
            title: 'Meerdere bankrekening',
            body: 'Heb je meerdere bankrekeningen? Met Tellow kan je tot wel 5 bankrekeningen koppelen aan jouw account.',
            links: `Begin nu`,
            url: `https://app.tellow.nl/registreer#/`,
        },
        {
            title: 'Jouw bank',
            body: 'Tellow heeft een koppeling met bijna alle Nederlandse banken. Of je nu bij de Rabobank, ING, Regio Bank of Knab zit, je rekening koppel je eenvoudig aan onze boekhoudtool.',
            links: `Koppel jouw bankrekening`,
            url: `https://app.tellow.nl/registreer#/`,
        },
    ],
    benefitImage: benefit,
    altImage: `Benefit Image`,
}

const KoppelJeRekening = () => (
    <Layout>
        {/* Page not indexed */}
        <Meta
            keywords={['bankrekening', 'zzp', 'tellow']}
            path="/functies/koppel-je-rekening"
            title="Koppel Je Rekening | Tellow.nl"
            description="Snel online boekhouden met hét online boekhoudprogramma voor zzp'ers ✅ 100.000+ ondernemers gingen je voor ✅ Registreer gratis!"
        />

        {/* First Component : Header (Main title and image) */}
        <Header data={headerData} showAnimation displayImage={false} />

        <ColoredBackgroundWithProps purple>
            <RelativeContainer>
                <ComponentContainer>
                    <HighlightedFunctionality data={highlightedFunctionalityData} purpleBackground bottomImage />
                </ComponentContainer>
            </RelativeContainer>
        </ColoredBackgroundWithProps>

        <RelativeContainer>
            <ComponentContainer>
                <Benefits data={benefitsData} />
            </ComponentContainer>
        </RelativeContainer>

        <ColoredBackgroundWithProps gray100>
            <RelativeContainer>
                <ComponentContainer>
                    <TopHighlights data={topHighlightData} />
                </ComponentContainer>
            </RelativeContainer>
        </ColoredBackgroundWithProps>

        <RelativeContainer>
            <ComponentContainer>
                <FeatureLinks data={featureData} />
            </ComponentContainer>
        </RelativeContainer>
    </Layout>
)

export default KoppelJeRekening
