import React from 'react'
import Lottie from 'lottie-react'
import BankConnectionAnimation from './bankConnection.json'
import { AnimationContainer } from './AnimationContainer.styles.js'

const LottieBankConnectionAnimation = () => (
    <AnimationContainer>
        <Lottie animationData={BankConnectionAnimation} />
    </AnimationContainer>
)

export default LottieBankConnectionAnimation
