import React from 'react'
import tw from 'twin.macro'

const Graph = () => (
    <svg css={tw`h-10 w-10 fill-current text-tellow-purple`} viewBox="0 0 36 36" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8.9999 5.3999C8.04512 5.3999 7.12945 5.77919 6.45432 6.45432C5.77919 7.12945 5.3999 8.04512 5.3999 8.9999V26.9999C5.3999 27.9547 5.77919 28.8704 6.45432 29.5455C7.12945 30.2206 8.04512 30.5999 8.9999 30.5999H26.9999C27.9547 30.5999 28.8704 30.2206 29.5455 29.5455C30.2206 28.8704 30.5999 27.9547 30.5999 26.9999V8.9999C30.5999 8.04512 30.2206 7.12945 29.5455 6.45432C28.8704 5.77919 27.9547 5.3999 26.9999 5.3999H8.9999ZM25.1999 12.5999C25.1999 12.1225 25.0103 11.6647 24.6727 11.3271C24.3351 10.9895 23.8773 10.7999 23.3999 10.7999C22.9225 10.7999 22.4647 10.9895 22.1271 11.3271C21.7895 11.6647 21.5999 12.1225 21.5999 12.5999V23.3999C21.5999 23.8773 21.7895 24.3351 22.1271 24.6727C22.4647 25.0103 22.9225 25.1999 23.3999 25.1999C23.8773 25.1999 24.3351 25.0103 24.6727 24.6727C25.0103 24.3351 25.1999 23.8773 25.1999 23.3999V12.5999ZM19.7999 16.1999C19.7999 15.7225 19.6103 15.2647 19.2727 14.9271C18.9351 14.5895 18.4773 14.3999 17.9999 14.3999C17.5225 14.3999 17.0647 14.5895 16.7271 14.9271C16.3895 15.2647 16.1999 15.7225 16.1999 16.1999V23.3999C16.1999 23.8773 16.3895 24.3351 16.7271 24.6727C17.0647 25.0103 17.5225 25.1999 17.9999 25.1999C18.4773 25.1999 18.9351 25.0103 19.2727 24.6727C19.6103 24.3351 19.7999 23.8773 19.7999 23.3999V16.1999ZM14.3999 21.5999C14.3999 21.1225 14.2103 20.6647 13.8727 20.3271C13.5351 19.9895 13.0773 19.7999 12.5999 19.7999C12.1225 19.7999 11.6647 19.9895 11.3271 20.3271C10.9895 20.6647 10.7999 21.1225 10.7999 21.5999V23.3999C10.7999 23.8773 10.9895 24.3351 11.3271 24.6727C11.6647 25.0103 12.1225 25.1999 12.5999 25.1999C13.0773 25.1999 13.5351 25.0103 13.8727 24.6727C14.2103 24.3351 14.3999 23.8773 14.3999 23.3999V21.5999Z"
            fill="currentColor"
        />
    </svg>
)

export default Graph
